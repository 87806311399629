
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import FormUserSelect from "./user-select.vue";

interface UserSellerOption {
    first_name: string;
    last_name: string;
    preview_url: string | null;
    id: number;
    type: "user" | "seller";
}

@Component({})
export default class FormUserSellerSelect extends FormUserSelect {
    @Prop() declare users: UserSellerOption[];

    @Prop({ default: () => [] }) declare value: UserSellerOption[];

    selectedUsersOrSellers: { id: number; type: "user" | "seller" }[] = [];

    get selectedUsers() {
        if (!this.users) {
            return [];
        }

        return this.users.filter((u) => this.isOptionSelected(u));
    }

    toggleSelected(option: UserSellerOption, canDelete: boolean = false) {
        const foundOptionIndex = this.getSelectedOptionIndex(option);

        if (foundOptionIndex !== -1 && !canDelete) {
            return;
        }

        if (this.multiple) {
            this.handleMultiTypedSelect(option);
        } else {
            this.handleSingleTypedSelect(option);
        }
    }

    handleMultiTypedSelect(option: UserSellerOption) {

        const foundOptionIndex = this.getSelectedOptionIndex(option);
 
        if (foundOptionIndex !== -1) {
            this.selectedUsersOrSellers.splice(foundOptionIndex, 1);
        } else {
            this.selectedUsersOrSellers.push({ id: option.id, type: option.type });
        }

        this.handleTypedInput();
    }

    handleSingleTypedSelect(option: UserSellerOption) {
        const foundOptionIndex = this.getSelectedOptionIndex(option);
        if (foundOptionIndex !== -1) {
            this.selectedUsersOrSellers = [];

            this.handleTypedInput();

            this.closeUserSelect();
            return;
        }
        this.selectedUsersOrSellers = [{ id: option.id, type: option.type }];

        this.handleTypedInput();

        this.closeUserSelect();
    }

    getSelectedOptionIndex(option: UserSellerOption): number {
        return this.selectedUsersOrSellers.findIndex((s) => s.id === option.id && s.type === option.type);
    }

    isOptionSelected(option: UserSellerOption): boolean {
        return this.getSelectedOptionIndex(option) !== -1;
    }

    @Emit("input")
    handleTypedInput() {
        return this.selectedUsersOrSellers;
    }

    // a bit shitty to use any / override method with possibly wrong signature
    // Yes very shitty because the output should always be number[] now tasks are broken..
    @Watch("value", { immediate: true })
    handleValueChanged(newValue: any[]) {
        this.selectedUsersOrSellers = newValue;
    }
}
